
.owned-property-card {
    /* background-color: rgb(241, 239, 239); */
    border: 2px solid rgb(125, 125, 125);
    padding: 1rem;
    border-radius: 10px;
    transition: all .5s;
    margin: 1rem 0;
    width: 70rem;
}

.owned-property-card:hover {
    background-color: rgb(249, 248, 248);
    cursor: pointer;
    transform: scale(1.02);
}

.txn span:hover {
    color: rgb(52, 100, 245);
    text-decoration: underline;
}

.property-header {
    display: flex;
    justify-content: space-between;
}

.property-actions {
    display: flex;
    align-items: flex-start;
}
.prop-header-title {
    margin-right: 1rem;
}

.prop-header-title h1 {
    line-height: 1.2rem;
    font-size: 1.5rem;
    font-weight: 400;
}

.prop-header-title p {
    color: rgb(109, 109, 109);
}

.property-actions button {
    margin-right: 1rem;
    padding: .2rem 1rem;
    cursor: pointer;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
    border: 1px solid black;
    outline: none;
    border-radius: 5px;
}

.owned-market-price {
    text-align: right;
    line-height: 1.5rem;
}

.owned-market-price h2 {
    font-weight: 400;
}

.property-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.sell-button {
    background-color: black;
    outline: none;
    border: none;
    color:  white;
    width: 8rem;
    padding: .5rem 2rem;
    border-radius: 5px;
    margin-left: 1rem;
    cursor: pointer;
    transition: all .2s;
    font-family: 'Poppins', sans-serif;

}

.sell-button:hover {
    transform: scale(1.02);
}
.sell-button:active {
    transform: scale(0.8);
}

.tx-id {
    display: flex;
}

.tx-id span {
    margin-left: 1rem;
}

.tx-process-info {
    display: flex;
}
.tx-process-info span {
    margin: 0 1rem;
}


.property-footer span {
    background-color: rgba(227, 227, 227, 0.471);
    padding: 0 .6rem;
    border-radius: 10px;
}