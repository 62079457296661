.hero-section {
    background-color: #E0D9CE;
    position: relative;
    z-index: -2;
    margin-top: -5rem;
    height: 40rem;
    padding-top: 5rem;
}

.featured-section {
    display: flex;
    justify-content: center;
}

.service-header {
    margin-top: 10rem;
    text-align: center;
    /* width: 20rem; */
    line-height: 1.8rem;
    /* width: 100; */
}

.svc-bg {
    position: absolute;
}

.svc-circle-1 {
    position: absolute;
    height: 80rem;
    width: 80rem;
    border-radius: 80rem;
    z-index: -2;
    background: radial-gradient(50% 50% at 50% 50%, rgba(221, 56, 56, 0.30) 0%, rgba(255, 99, 99, 0.00) 100%);
}

.svc-circle-1 {
    position: absolute;
    height: 80rem;
    width: 80rem;
    border-radius: 80rem;
    z-index: -2;
    background: radial-gradient(50% 50% at 50% 50%, rgba(59, 221, 56, 0.3) 0%, rgba(255, 99, 99, 0.00) 100%);
}

.svc-circle-2 {
    position: absolute;
    height: 80rem;
    width: 80rem;
    border-radius: 80rem;
    z-index: -2;
    margin-top: -20rem;
    margin-left: -40rem;
    background: radial-gradient(50% 50% at 50% 50%, rgba(32, 234, 180, 0.3) 0%, rgba(255, 99, 99, 0.00) 100%);

}


.service-header span {
    color: #1B82E0;
}

.services-offered {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 2rem;
}

.waiting-list-section {
    margin-top: 5rem;
    /* background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(0,152,155,1) 0.1%, rgba(0,94,120,1) 94.2% ); */
    background-image: linear-gradient(to top, #c1dfc4 0%, #deecdd 100%);
    /* height: 30rem; */
    display: flex;
    justify-content: center;
}

.wait-section {
    text-align: center;
    padding: 5rem 0;
}

.waitlist-email-input {
    margin: 2rem 0;
    width: 100%;
    height: 3rem;
    font-family: 'Poppins', sans-serif;
    padding: .5rem 1rem;
    outline: none;
    border: none;
    border-radius: 10px;
}


.wait-content {
    width: 45rem;
}

.tech-stacks-section {
    padding: 2rem 0;
    text-align: center;
    /* height: 25rem; */
    display: flex;
    justify-content: center;
}

.tech-content-container{
    width: 30rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
}
.tech-icon {
    height: 4rem;
    width: 4rem;
    margin: 0 3rem;
}

.marquee-flow {
    margin-bottom: 15rem;
}

.intro-header {
    text-align: center;
    background: -webkit-linear-gradient(#00fff7, #2405ee);
    background-clip: text;
    -webkit-text-fill-color: transparent;
   
}

.intro-header h2 {
    font-weight: 500;
}

.intro-web {
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-section {
    margin-top: 10rem;
    background-color: #fefae7;
    height: 45rem;
    display: flex;
    justify-content: center;
    padding: 5rem 0;
}

