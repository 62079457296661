.prop-image {
    /* height: 10rem; */
    width: 100%;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;

}

.property-card {
    background-color: rgb(255, 255, 255);
    border: 1px solid rgba(218, 218, 218, 0.332);
    border-radius: 10px;
    width: 18rem;
    transition: all .2s;
    cursor: pointer;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25); */
    margin: 1rem;
    transition: all .4s;
}

.property-card:hover {
    transform: scale(1.02);
    background-color: rgb(243, 243, 243);
    box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;

}


.prop-title-sec {
    padding: .5rem;
}

.prop-title-sec h1 {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.5rem;
}

.prop-title-sec p {
    font-size: .8rem;
    color: rgb(93, 93, 93);
}

.prop-owner {
    padding: 0 .5rem;
}

.prop-owner h2{
    color: rgb(148, 146, 146);
    margin-top: 1rem;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: .8rem;
}

.prop-owner span {
    font-size: .8rem;
    color: rgb(93, 93, 93);
}

.pricing-section {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
    padding: 0 .5rem;
    padding-bottom: 1rem;
}

.price h2{
    
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1rem;
}


.price p {
    font-size: .8rem;
}

.selling-price {
    text-align: right;
}

.selling-price h2 {
    color: rgb(6, 194, 6);
}

.button-section {
    padding: 0 .5rem;
}

.buy-button {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border-radius: 6px;
    border: 2px solid #ff4742;
    color: #fff;
    background-color: #ff4742;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
    font-weight: 800;
    font-size: 16px;
    height: 42px;
    width: 50%;
    transition: all .2s;

}

.buy-button:hover{
    background: 0 0;
    color: #ff4742;
}

.bid-button {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    border-radius: 6px;
    border: 2px solid #454545;
    color: #454545;
    background: 0 0;
    padding: 8px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 2px 4px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1.5px 0px;
    font-weight: 800;
    font-size: 16px;
    width: 50%;
    transition: all .2s;

    height: 42px;
}

.bid-button:hover{
    background-color: #454545;
    color: #fff;
}