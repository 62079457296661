.header { 
    display: flex;
    justify-content: space-between;
    padding: 1rem 2rem;
}

.logo-title {
    font-weight: 500;
    margin-left: .5rem;
}

.header-left {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.header-left img {
    height: 1.8rem;
}

.header a {
    text-decoration: none;
    color: black;
}


.header-right ul {
    display: flex;
    align-items: center;
}

.header-right li {
    list-style: none;
    margin-left: 4rem;
}

/* .header-login {
    background-color: black;
    outline: none;
    border: none;
    cursor: pointer;
    color:  white;
    padding: .5rem 2rem;
    border-radius: 5px;
} */

.header-login {
  font-family: "Poppins", sans-serif;
  width: 8rem;
  letter-spacing: 2px;
  text-decoration: none;
  text-transform: uppercase;
  color: #000;
  cursor: pointer;
  border: 3px solid;
  padding: 0.25em 0.5em;
  box-shadow: 1px 1px 0px 0px, 2px 2px 0px 0px, 3px 3px 0px 0px, 4px 4px 0px 0px, 5px 5px 0px 0px;
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.header-login:active {
  box-shadow: 0px 0px 0px 0px;
  top: 5px;
  left: 5px;
}

@media (min-width: 768px) {
  .header-login {
    padding: 0.25em 0.75em;
  }
}

.userwallet-addr-cnt {
  cursor: pointer;
  padding: 0 1rem;
  border: 2px solid black;
  border-radius: 8px;
  transition: all .3s;
  font-weight: 500;
}

.userwallet-addr-cnt:hover {
  background-color: beige;
}