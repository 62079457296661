.service-card {
    width: 17rem;
    padding: 2rem 1rem;
    transition: all .2s;
    cursor: pointer;
}

.service-card:hover {
    background-color: rgba(220, 219, 219, 0.609);
    border-radius: 10px;
}

.service-card p {
    margin-top: 4rem;
}