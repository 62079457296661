.about-header h1 {
  position: absolute;
  font-size: 20rem;
  color: rgba(97, 97, 97, 0.218);
  z-index: -2;
  margin-top: 25rem;
}

.about-page {
  padding: 0 2%;
}

.about-me-content {
  height: 30rem;
  width: 100%;
  border-radius: 20px;
  background-color: rgb(0, 198, 155);
  padding: 2rem;
  display: flex;
  margin-bottom: 20rem;
}

.abt-left-content {
  width: 50%;
}

.abt-left-content p {
  text-align: justify;
}

.abt-right-content {
  width: 50%;
  display: flex;
  padding: 0 6rem;
  /* align-items: center; */
}

.creator-words {
  color: rgb(50, 50, 50);
}

.sign {
  float: right;
  font-style: italic;
}