.listing-tabs {
    display: flex;
    background-color: white;
    width: fit-content;
}

.search-fields {
    display: flex;
}

.tab {
    width: 5rem;
    text-align: center;
    padding: .2rem 1rem;
    cursor: pointer;
}

/* .buy-tab {
    background-color: white;
} */

.selected {
    background-color: orange;
}

.search-fields {
    background-color: white;
    width: fit-content;
    padding: 1rem 2rem;
    box-shadow: 0px 25px 69px 12px rgba(0, 0, 0, 0.25);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.prop-search {
    border: none;
    outline: none;
    font-family: 'Poppins', sans-serif;
}

.search-property {
    padding: 0 2rem;
    background: #3D5BF5;
    border: none;
    outline: none;
    border-radius: 8px;
    font-family: 'Poppins', sans-serif;
    color: white;
    font-size: 1.1rem;
    cursor: pointer;
    transition: all .5s;
}

.search-property:active{
    transform: scale(0.8);
}

.search-act {
    margin-right: 1rem;
}