
.contact-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4rem;
}

.contact-other-side {
    width: 40rem;
    padding: 0 3rem;
    margin-top: 5rem;
}

.contact-header {
    width: 40rem;
    padding: 0 3rem;

}
.contact-header h1{
    font-size: 4rem;
}
.contact-input {
    color: rgba(0,0,0,.87);
    border-bottom-color: rgba(0,0,0,.42);
    font-size: 1rem;
    width: 20rem;
    font-weight: 400;
    letter-spacing: .009375em;
    text-decoration: inherit;
    text-transform: inherit;
    align-self: flex-end;
    box-sizing: border-box;
    padding: 20px 16px 6px;
    transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
    border: none;
    border-bottom: 1px solid;
    border-radius: 4px 4px 0 0;
    background: rgba(245, 245, 245, 0.12);
    height: 56px;
    outline: none;
    margin: 1rem;
    font-family: 'Poppins', sans-serif;
}
.contact-input:hover{
    border-bottom-color: rgba(0,0,0,.87);
    background: #68686828;
}

.contact-input:focus{
    border-color: #222222;
}

.form-div {
    display: flex;
}

.company-name, .message {
    width: 100%;
}

.message {
    font-family: 'Poppins', sans-serif;
}

.send-it-section {
    padding: 0 1rem;
}
.send-it  {
    width: 100%;
    background-color: black;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    padding: 0.5rem;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all .5s;
    /* height: 2.5rem; */
}

.send-it:hover {
    transform: scale(1.02);
}