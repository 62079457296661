.footer {
    padding: 1rem 2rem;
    /* margin-top: 4rem; */
    background-color: #fefae7;
}
.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
}

.footer-logo {
    height: 3rem;
}

.footer-logo-title {
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: -.8rem;
}

.git-logo {
    height: 2rem;
    width: 2rem;
    margin-left: 1rem;
}

.hr-line {
    width: 100%;
    height: 2px;
    background-color: rgb(174, 174, 174);
}

.foot-right {
    text-align: right;
}

.footer-container-2 {
    display: flex;
    padding: .5rem 0;
    justify-content: space-between;
}