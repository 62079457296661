.ft-image-1, .ft-image-2 {
    height: 20rem;
    transition: all 1s;
    cursor: pointer;
    border-radius: 10px;
    box-shadow: 0px 10px 20px rgba(105, 105, 105, 0.1);
}

.ft-image-2 {
    position: absolute;
    z-index: 2;
    margin-top: 2rem;
    margin-left: -10rem;
    transition: all 1s;
    cursor: pointer;
}

.ft-image-1:hover {
    transform: translateY(-50px);
}

.ft-image-2:hover {
    transform: translateY(-50px);
}

.featured-container {
    display: flex;
    margin: 4rem 0;
}

.ft-content {
    width: 40rem;
    padding: 0 4rem;
}

.ft-content h1 {
    font-weight: 400;
    line-height: 2.5rem;
}