.listing-page-bg {
    background-color: antiquewhite;
    height: 25rem;
}
.listing-container {
    display: flex;
    justify-content: center;
    position: relative;
    margin-top: -25rem;
}

.listing-search-header {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 5rem 0;
    /* width: 50rem; */
    height: 20rem;
}

.listed-properties {
    position: relative;
    margin-top: -5rem;
    display: flex;
    justify-content: center;
    width: 60rem;
    flex-wrap: wrap;
}

.listed-search {
    width: 100%;
    height: fit-content;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    border-radius: 15px;
}
.search-location {
    height: fit-content;
    padding: 1rem;
    /* height: fit-content; */
    width: 100%;
    border: none;
    outline: none;
    font-size: 1.2rem;
    font-family: 'Poppins', sans-serif;
}

.search-results {
    padding: 1rem .5rem;
}

.src-icon {
    height: 2.5rem;
    width: 2.5rem;
    margin: 0 .5rem;
}